<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="所属项目：">
                        <span v-if="m.project">{{m.project.name}}</span>
                    </el-form-item>

                     <el-form-item label="头像">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false" :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_avatar" :before-remove="remove_avatar" :file-list="m.avatarFile">
                            <el-button size="mini" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传头像</div>
                        </el-upload>
                    </el-form-item>


                    <el-form-item label="问题图片">
                        <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false" :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_image" :before-remove="remove_image" :file-list="m.imageFile">
                            <el-button size="mini" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">上传问题图片</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="问题内容">
                        <el-input type="textarea" rows="2" placeholder="问题内容" v-model="m.content"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <input-enum enumName="feedBackStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                   

                    <el-form-item label="联系方式" prop="mobile">
                        <el-input v-model="m.mobile"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                projectId: [],
                image: [],
                content: [],
                state: [],
                avatar: []
            },
            fileList: []
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改 建议反馈";
                data.imageFile = JSON.parse(data.image);
                data.avatarFile = JSON.parse(data.avatar);
                this.m = data;
            } else {
                this.m = {
                    projectId: data.project.id,
                    image: "",
                    imageFile: [],
                    content: "",
                    state: 0,
                    avatar: 0,
                    avatarFile: [],
                    mobile: ""
                };
                this.m.project = data.project;
                this.title = "添加 建议反馈";
            }
            console.log(this.m);
        },
        success_image(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.imageFile) {
                this.m.imageFile = [];
            }
            this.m.imageFile.push(response.data);
            console.log(fileList);
        },
        remove_image(file, fileList) {
            this.m.imageFile = fileList;
        },
        success_avatar(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.avatarFile) {
                this.m.avatarFile = [];
            }
            this.m.avatarFile.push(response.data);
            console.log(fileList);
        },
        remove_avatar(file, fileList) {
            this.m.avatarFile = fileList;
        },

        //提交建议反馈信息
        ok: function(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.m.image = JSON.stringify(
                        this.m.imageFile.map(item => {
                            let a = {};
                            a.name = item.name;
                            a.url = item.url;
                            return a;
                        })
                    );
                    this.m.avatar = JSON.stringify(
                        this.m.avatarFile.map(item => {
                            let a = {};
                            a.name = item.name;
                            a.url = item.url;
                            return a;
                        })
                    );
                    this.sa.post("/feedback/save", this.m).then(res => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    },
    created() {}
};
</script>